/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Auth, API } from "aws-amplify";
import { parseCookies, setCookie } from "nookies";
import { Button, Divider, List, ListItem, ListItemText } from "@mui/material";

import {
  RoleEnum,
  permissions as permissionsObj,
  IPermissions,
} from "utils/permission";
import { getAuthUser } from "graphql/user";
import { verifyEmail } from "graphql/mutations";
import { Group, User, UsersGroups, Tenant, UsersTenants } from "API";
import { useAlerts } from "contexts/alerts";

type Modify<T, R> = Omit<T, keyof R> & R;

type GroupWithRole = Modify<
  Group,
  {
    role: RoleEnum;
  }
>;

export type CognitoUser = Modify<
  User,
  {
    attributes: {
      sub: string;
      email: string;
      email_verified: string;
    };
    username: string;
    tenantId?: string;
    groups: GroupWithRole[];
    tenants: Tenant[];
    isAdmin: boolean;
    disableMultiTenant: boolean;
  }
>;

type Challenge =
  | null
  | "new-password"
  | "forgot-password"
  | "submit-forgot-password";

interface AuthContextValue {
  user: CognitoUser | undefined;
  tenants: Tenant[];
  loadingUser: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
  currentGroup: GroupWithRole | undefined;
  setCurrentGroup: (group: GroupWithRole | undefined) => void;
  setNewPassword: (password: string) => void;
  challenge: Challenge;
  setChallenge: (challenge: Challenge) => void;
  forgotPassword: (email: string) => void;
  submitForgotPassword: (code: string, password: string) => void;
  permissions: IPermissions;
}

const AuthContext = createContext<AuthContextValue>({
  user: undefined,
  tenants: [],
  currentGroup: undefined,
  loadingUser: true,
  login: () => null,
  logout: () => null,
  setCurrentGroup: () => null,
  setNewPassword: () => null,
  challenge: null,
  setChallenge: () => null,
  forgotPassword: () => null,
  submitForgotPassword: () => null,
  permissions: permissionsObj[RoleEnum.EMPTY],
});

interface ProviderProps {
  children: ReactNode | ReactNode[];
}

interface GraphQLResult {
  data: {
    [key: string]: any;
  };
  errors: any;
}

const getUserData = async (
  cognitoUser: CognitoUser,
  setUser: Dispatch<SetStateAction<CognitoUser | undefined>>,
  currentGroup: GroupWithRole | undefined,
  setCurrentGroup: Dispatch<SetStateAction<GroupWithRole | undefined>>,
  setTenants: Dispatch<SetStateAction<Tenant[] | []>>,
  host: string
) => {
  const res = (await API.graphql({
    query: getAuthUser,
    variables: { id: cognitoUser.attributes.sub },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  })) as GraphQLResult;

  if (res.data.getUser) {
    const userData = res.data.getUser;

    const tenants =
      userData.tenants.items.map((i: UsersTenants) => i.active && i.tenant) ||
      [];

    const tenant = tenants.find((t: Tenant) => t.host === host);

    const tenantUser = userData.tenants?.items.find(
      (i: UsersTenants) => i.active && i.tenantId === tenant?.id
    );

    let tenantId = tenant?.id;
    let isAdmin = tenantUser?.isAdmin || false;

    let userGroups = [];
    if (!!userData.tenant.disableMultiTenant) {
      tenantId = userData.tenantId;
      isAdmin = userData.isAdmin;
      if (userData.isAdmin) {
        userGroups =
          userData?.tenant.groups.items.map((g: Group) => ({
            ...g,
            role: RoleEnum.ADMIN,
          })) || [];
      } else {
        userGroups =
          userData.groups.items?.map((i: UsersGroups) => ({
            ...i.group,
            role: i.role,
          })) || [];
      }
    } else {
      if (!!tenant && isAdmin) {
        userGroups =
          tenant?.groups.items.map((g: Group) => ({
            ...g,
            role: RoleEnum.EMPTY,
          })) || [];
      } else if (!!tenant) {
        userGroups =
          userData.groups.items
            .filter((g: UsersGroups) => g.group?.tenantId === tenant?.id)
            ?.map((i: UsersGroups) => ({ ...i.group, role: i.role })) || [];
      } else {
        userGroups = [];
      }
    }

    let groups = userGroups;

    const cookies = parseCookies();
    const savedCurrentGroupId = cookies.currentGroupId;
    const savedCurrentGroup = userGroups.find(
      (g: Group) => g.id === savedCurrentGroupId
    );

    if (!savedCurrentGroup) {
      setCookie(null, "currentGroupId", "", {
        path: "/",
      });
    }

    if (savedCurrentGroup) {
      setCurrentGroup(savedCurrentGroup);
    } else if (userGroups.length > 0 && !currentGroup) {
      setCurrentGroup(userGroups[0]);
      setCookie(null, "currentGroupId", userGroups[0].id, {
        path: "/",
      });
    }

    setTenants(tenants);

    setUser({
      ...cognitoUser,
      ...userData,
      groups,
      tenantId,
      isAdmin,
      disableMultiTenant: userData.tenant.disableMultiTenant,
    });
  }
};

export const AuthProvider = ({ children }: ProviderProps) => {
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(
    undefined
  );
  const [user, setUser] = useState<CognitoUser | undefined>(undefined);
  const [tenants, setTenants] = useState<Tenant[] | []>([]);
  const [currentGroup, setCurrentGroup] = useState<GroupWithRole | undefined>(
    undefined
  );
  const [challenge, setChallenge] = useState<Challenge>(null);
  const [email, setEmail] = useState<string>("");
  const { addAlert } = useAlerts();
  const host = typeof window !== "undefined" ? window.location.hostname : "";

  const permissions = useMemo(() => {
    if (!user) {
      return permissionsObj[RoleEnum.EMPTY];
    }
    if (!currentGroup) {
      return permissionsObj[RoleEnum.EMPTY];
    }
    if (user.isAdmin) {
      return permissionsObj[RoleEnum.ADMIN];
    }
    return permissionsObj[currentGroup.role];
  }, [user, currentGroup]);

  const setGroupWithCookie = (group: GroupWithRole | undefined) => {
    setCurrentGroup(group);
    // クッキーにcurrentGroupを保存
    setCookie(null, "currentGroupId", group?.id || "", {
      path: "/",
    });
  };

  useEffect(() => {
    setLoadingUser(true);
    Auth.currentAuthenticatedUser()
      .then((usr) =>
        getUserData(
          usr,
          setUser,
          currentGroup,
          setCurrentGroup,
          setTenants,
          host
        )
      )
      .catch(() => setUser(undefined))
      .finally(() => setLoadingUser(false));
  }, []);

  const login = (username: string, password: string) => {
    setLoadingUser(true);
    Auth.signIn(username, password)
      .then(async (usr) => {
        if (!usr.challengeName) {
          await getUserData(
            usr,
            setUser,
            currentGroup,
            setCurrentGroup,
            setTenants,
            host
          );
          return usr;
        }
        if (usr.challengeName === "NEW_PASSWORD_REQUIRED") {
          setCognitoUser(usr);
          return setChallenge("new-password");
        }
      })
      .catch((err) => {
        setUser(undefined);
        if (err.code === "UserNotFoundException") {
          return addAlert({
            message: "ユーザーが見つかりません",
            severity: "error",
          });
        }
        if (err.code === "NotAuthorizedException") {
          return addAlert({
            message: "パスワードが間違えています",
            severity: "error",
          });
        }
        return addAlert({
          message: err.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoadingUser(false);
      });
  };

  const logout = () => {
    setCookie(null, "currentGroupId", "", {
      path: "/",
    });
    Auth.signOut().then((data) => {
      setUser(undefined);
      return data;
    });
  };

  const forgotPassword = async (email: string) => {
    Auth.forgotPassword(email)
      .then(() => {
        setEmail(email);
        setChallenge("submit-forgot-password");
      })
      .catch((err) => {
        setEmail("");
        if (err.code === "UserNotFoundException") {
          return addAlert({
            message: `ユーザーが見つかりません`,
            severity: "error",
          });
        }
        return addAlert({
          message: `Eメールが無効です：${err.message}`,
          severity: "error",
        });
      });
  };

  const submitForgotPassword = async (code: string, password: string) => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        setEmail("");
        setChallenge(null);
        return addAlert({
          message: "パスワードがリセットされました",
          severity: "success",
        });
      })
      .catch((err) => {
        if (err.code === "CodeMismatchException") {
          return addAlert({
            message: "認証コードが一致しません",
            severity: "error",
          });
        }
        return addAlert({
          message: `パスワードのリセットに失敗しました：${err.message}`,
          severity: "error",
        });
      });
  };

  const setNewPassword = async (password: string) => {
    try {
      await Auth.completeNewPassword(cognitoUser, password);
      // キャッシュを無視して最新の認証情報を取得
      const updatedUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      (await API.graphql({
        query: verifyEmail,
        variables: { input: { email: updatedUser?.username } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as GraphQLResult;
      setUser(undefined);
      setChallenge(null);
    } catch (err: any) {
      if (err.code === "InvalidPasswordException") {
        return addAlert({
          message: `パスワードが無効です：${err.message}`,
          severity: "error",
        });
      }
      addAlert({
        message: err.message,
        severity: "error",
      });
    }
  };

  // useMemo to prevent force re-render components that are reading these values
  const values = useMemo(
    () => ({
      user,
      tenants,
      login,
      logout,
      loadingUser,
      currentGroup,
      setCurrentGroup: setGroupWithCookie,
      setNewPassword,
      challenge,
      setChallenge,
      forgotPassword,
      submitForgotPassword,
      permissions,
    }),
    [
      user,
      tenants,
      loadingUser,
      currentGroup,
      setGroupWithCookie,
      setNewPassword,
      challenge,
      setChallenge,
      forgotPassword,
      submitForgotPassword,
      permissions,
    ]
  );

  if (!!user && !user?.tenantId && tenants.length === 0) {
    return (
      <div className="h-screen flex gap-10 justify-center items-center">
        <div>
          <div className="w-36 mb-10">
            <img src="/logo.png" alt="logo" />
          </div>
          <div className="mb-10">
            テナントに所属されていません。管理者に連絡してください。
          </div>
          <Button variant="outlined" color="primary" onClick={() => logout()}>
            ログアウト
          </Button>
        </div>
      </div>
    );
  }

  if (!!user && !user?.tenantId && tenants.length > 0) {
    return (
      <div className="h-screen w-screen flex gap-10 justify-center items-center">
        <div>
          <div className="w-36 mb-10">
            <img src="/logo.png" alt="logo" />
          </div>
          <div className="mb-3">テナントを選択して下さい。</div>
          <List className="mb-10 w-[500px]">
            {tenants.map((tenant) => (
              <>
                <ListItem
                  key={tenant.id}
                  secondaryAction={
                    <div className="flex gap-10 text-blue-600">
                      <a href={`https://${tenant.host}`}>管理者画面</a>
                      <a href={`https://${tenant.mobileHost}`}>モバイル画面</a>
                    </div>
                  }
                >
                  <ListItemText>{tenant.name}</ListItemText>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
          <Button variant="outlined" color="primary" onClick={() => logout()}>
            ログアウト
          </Button>
        </div>
      </div>
    );
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (authContext === undefined) {
    throw new Error("useAuth must be within AuthProvider");
  }

  return authContext;
};
